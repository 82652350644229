import { gql } from "@apollo/client";

export const Vessel_query = gql`
  query vesselsForCustomer($id: ID!) {
    vesselsForCustomer(custId: $id) {
      id
      name
      imo
      mmsi
      description
      imageURL
      typeDescription
      displayName
      contactNumber
      timezone
      yardNumber
    }
  }
`;

export const equipment_query = gql`
  query getEquipmentsByVessel($vesselId: Int!) {
    getEquipmentsByVessel(vesselId: $vesselId) {
      id
      name
    }
  }
`;
