import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fetch from "cross-fetch";
import config from "../config";

import { msalConfig } from "../utils/authConfig.js";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalInstance } from "../index.js";
import jwt_decode from "jwt-decode";


const httpLink = new HttpLink({ uri: `${config.graphQlendpoint}`, fetch });
let user;
const authMiddleware = new ApolloLink(async (operation, forward) => {
  // const userEmail = localStorage.getItem("user_email");
  // const encryptedEmail = userEmail !== null ? btoa(userEmail) : null;

  let token = await getToken();
  let access_decode=jwt_decode(token.accessToken);
  // add the authorization to the headers
  operation.setContext({
    headers: {
      "Authorization": "Bearer " + token.accessToken,
      "Authorization-Id": token.idToken,
      "User-Email": btoa(access_decode.email ? access_decode.email : access_decode.unique_name),
      "Application": btoa(token.application),
    },
  });

  return forward(operation);
});

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let errorCode =
      graphQLErrors[0].extensions && graphQLErrors[0].extensions.code;
    if (errorCode === "UNAUTHENTICATED") {

    }
    if (errorCode === "TOKEN_EXPIRED") {

    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const getToken = async () => {
  const account = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ["user.read"],
    account: account,
  };
  const token = await msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      let idToken = accessTokenResponse.idToken;
      let application = accessTokenResponse.account.idTokenClaims.aud;
      let tokens = {
        accessToken,
        idToken,
        application
      };
      return tokens;
    })
    .catch(function (error) {
      if (error) {
        msalInstance
          .acquireTokenPopup(accessTokenRequest)
          .then(function (accessTokenResponse) {
            let accessToken = accessTokenResponse.accessToken;
            let idToken = accessTokenResponse.idToken;
            let application = accessTokenResponse.account.idTokenClaims.aud;
            let tokens = {
              accessToken,
              idToken,
              application
            };
            return tokens;
          })
          .catch(function (error) {
            msalInstance.logoutRedirect({
              postLogoutRedirectUri: "/",
            });
          });
      }
      console.log(error);
    });

  return token;
};

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, logoutLink, httpLink]),
});
