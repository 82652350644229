import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Row, Button } from "react-bootstrap";
import "./ServiceRequestForm.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Calendar } from "primereact/calendar";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ServiceRequestForm = (props) => {
  let history = useNavigate();

  //Work Report Modal
  const [show, setShowReport] = useState(false);
  const handleClose = () => setShowReport(false);

  const goBack = () => {
    history(-1);
  };

  const openWorkReportForm = () => {
    setShowReport(true);
  };

  let fileUploaded1;

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    fileUploaded1 = event.target.files[0];
  };
  return (
    <div className="service-form-wrapper">
      <div className="header-section">
        <span
          onClick={() => goBack()}
          className="backIcon"
          style={{ color: "blue" }}
        >
          <MdArrowBack />
        </span>
        <h5 className="app-header">TRITON GUARD</h5>
      </div>

      <div className="title">
        <div className="request-header">Request Details</div>
        <div className="welcome">
          <div className="user-box">
            <img src="https://picsum.photos/200/300?random=3" alt="" />
          </div>
          <div className="vessel-name">Vessel 2</div>
          <div className="vessel-number">IMO : 12345</div>
        </div>
      </div>
      <div className="issue-description-wrapper">
        <Row className="issue-container">
          <div className="issue-description">Abnormal Sound from Engine</div>
          <div className="part-specification">Main Engine</div>
          <div className="issue-time-status">
            <div className="issue-timestamp">5th July 2021</div>
            <div className="issue-status-description-form pending">
              Pending with Service Provider
            </div>
          </div>
          <div className="detailed-description">
            <div className="description-header">Description</div>
            <div className="description-details">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div className="description-images-complete">
              <img
                src="https://picsum.photos/200/300?random=1"
                alt="image1"
                width="100"
                height="50"
              ></img>
              <img
                src="https://picsum.photos/200/300?random=2"
                alt="image2"
                width="100"
                height="50"
              ></img>
            </div>
          </div>
        </Row>
      </div>
      <div className="workflow-wrapper">
        <div className="workflow-header">Workflow</div>
        <div className="workflow-steps-form">
          <div className="step-summary-form">
            <div className="worflow-diagram">
              <div className="workflow-round">
                <div className="workflow-round-completed"></div>
              </div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Estimate Time and Date</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">
                Julia ,Service Provider
              </div>
              <div className="step-status">Completed</div>
            </div>
            <div className="step-comment">
              <div className="step-comment-text">
                "Tentative Estimate specified"
              </div>
            </div>
          </div>
          <div className="step-summary-form">
            <div className="worflow-diagram">
              <div className="workflow-round">
                <div className="workflow-round-completed"></div>
              </div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Remote Access Approval</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">James ,Captain</div>
              <div className="step-status">Completed</div>
            </div>
            <div className="step-comment">
              <div className="step-comment-text">"Verfied and Approved"</div>
            </div>
          </div>
          <div
            className="step-summary-form active"
          >
            <div className="worflow-diagram">
              <div className="workflow-round"></div>
              <div className="workflow-arrow"></div>
            </div>
            <div className="step-details">
              <div className="step-description">Report Work</div>
              <div className="step-timestamp">5th May 2021,5:20 PM</div>
            </div>
            <div className="step-approval">
              <div className="step-approver-details">
                Julia,Service Provider
              </div>
              <div className="step-status">
                <Button className="pending-button" onClick={() => openWorkReportForm()}>
                  Take Action
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pending-steps-wrapper">
        <div className="task-header">To be started</div>
        <div className="task-list-body">
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Report Work</div>
          </div>
          <div className="task-list-details">
            <div className="workflow-round"></div>
            <div className="task-name">Review Work</div>
          </div>
        </div>
      </div>
      <div className="report-form-modal-wrapper">
        <Modal
          className="report-form-modal"
          show={show}
          onHide={handleClose}
          animation={false}
          backdrop={false}
          style={{ background: "#0000004d" }}
        >
          <Modal.Header closeButton>WORK REPORT</Modal.Header>
          <Modal.Body>
            {/* <div className="request-estimate-header"></div> */}
            <Form.Group className="mb-2 work-report-title" controlId="formBasicIssueTitle">
              <Form.Label className="form-label">Title</Form.Label>
              <Form.Control />
            </Form.Group>
            <Form.Group className="mb-0 work-report-description" controlId="ControlTextarea">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
            <Form.Group
              className="mt-4 mb-2 button-container"
              controlId="fileUploadControl"
            >
              <Button onClick={handleClick}>
                <span className="button-text">Upload file</span>
              </Button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              ></input>
            </Form.Group>
          </Modal.Body>
          <div className="comment-button-group">
            <Button variant="secondary close-form" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="secondary submit-approval" onClick={handleClose}>
              SUBMIT
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ServiceRequestForm;
