import React, { Component } from "react";
import { AuthProvider } from "./providers/AuthProvider";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import ServiceRequestList from "./components/ServiceRequestList/ServiceRequestList";
import ServiceRequestDetails from "./components/ServiceRequestDetails/ServiceRequestDetails";
import ServiceRequestForm from "./components/ServiceRequestForm/ServiceRequestForm";
import ServiceDetailsResolved from "./components/ServiceDetailsResolved/ServiceDetailsResolved";
import ServiceWorkerWrapper from "./serviceWorkerWrapper.tsx";
import Login from "./components/login/login";
import ProtectedRoute from "./utils/protectedRoute";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { ApolloProvider } from "@apollo/client";
import { client } from "./services/GraphqlService";
import { msalInstance } from "./index.js";
import Downtime from "./components/DownTime/DownTime";

class App extends Component {
  componentDidMount() {
    msalInstance.handleRedirectPromise().then((resp) => {
      if (resp && resp.account) msalInstance.setActiveAccount(resp.account);
    });
  }
  render() {
    return (
      <ServiceWorkerWrapper>
        <Downtime />
        <AuthProvider>
          <ApolloProvider client={client}>
            <div className="guard-wrapper">
              <HashRouter basename="/">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/serviceRequestList"
                    exact
                    element={
                      <ProtectedRoute>
                        <ServiceRequestList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/serviceDetails"
                    exact
                    element={
                      <ProtectedRoute>
                        <ServiceRequestDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/serviceRequestForm"
                    exact
                    element={<ServiceRequestForm />}
                  />
                  <Route
                    path="/serviceDetailsResolved"
                    exact
                    element={<ServiceDetailsResolved />}
                  />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              </HashRouter>
            </div>
          </ApolloProvider>
        </AuthProvider>
      </ServiceWorkerWrapper>
    );
  }
}

export default App;
